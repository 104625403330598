<template>
  <div class="index-container">
    <div class="bread">
      财务管理 /
      <span class="gray" @click="goBack">未付列表</span> /
      <span>未付详情</span>
    </div>
    <div class="detail-body">
      <h5 class="title">未付款详情</h5>
      <div class="date-body">
        <el-form ref="form" :model="moneyInfo" :rules="ruleMoney" label-position="right" class="info" style="padding-top: 30px">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="5">
              <el-form-item label="车牌号" :label-width="formLabelWidth" prop="carId">
                <el-input v-model="moneyInfo.carNum" autocomplete="off" placeholder="未设置" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="5">
              <el-form-item label="负责人" :label-width="formLabelWidth" prop="chargeName">
                <el-input v-model="moneyInfo.chargeName" autocomplete="off" placeholder="未设置" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="5">
              <el-form-item label="付款日期" :label-width="formLabelWidth" prop="expectDate">
                <el-date-picker
                    disabled
                    v-model="moneyInfo.expectDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择付款日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="5">
              <el-form-item label="付款金额" :label-width="formLabelWidth" prop="amount">
                <el-input  v-model="moneyInfo.amount" autocomplete="off" placeholder="请输入应付金额" disabled>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="5">
              <el-form-item label="实付日期" :label-width="formLabelWidth" >
                <el-date-picker
                    v-model="moneyInfo.payDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="请选择实付日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="7" :xl="5">
              <el-form-item label="实付金额" :label-width="formLabelWidth" prop="reciveAmount">
                <el-input  v-model="moneyInfo.reciveAmount" autocomplete="off" placeholder="请输入实付金额">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form ref="form" :model="moneyInfo" :rules="ruleMoney" label-position="right" class="info">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="20" :lg="14" :xl="10">
              <el-form-item label="付款类型" :label-width="formLabelWidth" prop="receiveType">
                <el-radio-group v-model="moneyInfo.receiveType" disabled>
                  <el-radio :label="1">维修费</el-radio>
                  <el-radio :label="2">保养费</el-radio>
                  <el-radio :label="3">年检费</el-radio>
                  <el-radio :label="4">保险费</el-radio>
                  <el-radio :label="5">押金</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="6">
              <el-form-item label="付款状态" :label-width="formLabelWidth" prop="status">
                <el-radio-group v-model="moneyInfo.status">
                  <el-radio :label="1">未付</el-radio>
                  <el-radio :label="2">已付</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="20" :lg="14" :xl="10">
              <el-form-item label="备注" :label-width="formLabelWidth" prop="remark">
                <el-input v-model="moneyInfo.remark" autocomplete="off" placeholder="请输入备注"
                          type="textarea"
                          rows="6"
                          maxlength="200"
                          show-word-limit
                          resize="none"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="buttons">
          <el-button type="default" size="medium" plain @click="close">取消</el-button>
          <el-button type="primary" size="medium" @click="submit">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {isPayDetail, isPayEdit} from "@/utils/api/financial";

export default {
  //未付详情
  name: "sf",
  data(){
    var checkMoney = (rule, value, callback) =>{
      if(value!=this.moneyInfo.amount){
        callback(new Error("实付金额与应付金额不一致"));
      }else{
        callback()
      }
    }
    return{
      formLabelWidth:"80px",
      labelPosition:"right",
      carList:[],
      value: [],
      list: [],
      options: [],
      loading: false,
      moneyInfo:{},
      ruleMoney:{
        payDate:[
          { required: true, message: "请选择实付日期", trigger: 'blur' },
        ],
        status:[
          { required: true, message: "请选择付款状态", trigger: 'blur' },
        ],
        reciveAmount:[
          { required: true, message: "请选输入实收金额", trigger: 'blur' },
          { validator: checkMoney, trigger: ['blur','change']  }
        ]
      }
    }
  },
  created() {
    if (!this.$route.params){
      this.$baseMessage('缺少款项参数','warning')
      setTimeout(() => {
        history.back();
      }, 2000);
      return
    }
    let date = this.$route.params.id
    this.getDetail(date)
  },
  methods:{
    goBack(){
      history.back()
    },
    close(){
      history.back();
    },
    submit(){
      this.$refs['form'].validate(async(valid)=>{
        if (valid){
          isPayEdit(this.moneyInfo).then(res=>{
            if (res.code==0){
              this.$baseMessage('修改成功','success')
              setTimeout(() => {
                history.back();
              }, 200);

            }else{
              this.$baseMessage(res.msg,'error')
            }
          })
        }else{
          this.$baseMessage('请检查提交的信息','warning')
        }
      })
    },
    getDetail(id){
      let form={
        id:id
      }
      isPayDetail(form).then(res=>{
        this.moneyInfo=Object.assign({},this.moneyInfo, res.data)
      })
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.getCarList(query)
        }, 200);
      } else {
        this.carList = [];
      }
    },
    getCarList(key){
      let form={
        carNum:key,
        type:3,
        rows:100000
      }
      carList(form).then(res=>{
        this.carList=res.data.list
      })
    },
  }
}
</script>

<style lang="less">
.detail-body{
  background-color: #FFFFFF;
  padding: 30px;
  .title{
    height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }
  .date-body{
    .el-form{
      .el-row{
        .el-form-item{
          .el-form-item__label{
            font-size: 14px;
            font-weight: 400;
            color: #333333;
          }
          .el-input.is-disabled .el-input__inner{
            border: none;
            background: #FAFAFA;
            border-radius: 2px;
            color: #333333 ;
          }
          .el-input-group__append, .el-input-group__prepend{
            background: #FAFAFA;
            color: #333333 ;
            border: none;
          }
          .el-textarea.is-disabled .el-textarea__inner{
            background: #FAFAFA;
            color: #333333 ;
            border: none;
          }
        }
      }
    }
  }
  .buttons{
    text-align: right;
  }
}
</style>

