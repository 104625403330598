/**
 * @notice 财务相关
 * @author hhq
 */
import http from "../http"

//收支记录查询
export const isPayList =(params)=>{
    return http.getRequest('/signpayrecord/page',params)
}
//收支记录详情
export const isPayDetail=(params)=>{
    return http.getRequest('/signpayrecord/',params)
}
//收支记录新增
export const isPayAdd=(params)=>{
    return http.postRegister('/signpayrecord/add',params)
}
//收支记录修改
export const isPayEdit=(params)=>{
    return http.postRegister('/signpayrecord/update',params)
}
//收支记录删除
export const isPayDel=(params)=>{
    return http.getRequest('/signpayrecord/del',params)
}


//收款记录查询
export const noPayList =(params)=>{
    return http.getRequest('/rentremind/page',params)
}
//收款记录详情
export const noPayDetail=(params)=>{
    return http.getRequest('/rentremind/',params)
}
//收款记录新增
export const noPayAdd=(params)=>{
    return http.postRegister('/rentremind/add',params)
}
//收款记录修改
export const noPayEdit=(params)=>{
    return http.postRegister('/rentremind/update',params)
}
//收款记录删除
export const noPayDel=(params)=>{
    return http.getRequest('/rentremind/del',params)
}

//财务统计
export const allMoney =(params)=>{
    return http.getRequest('/home/getFinanceCount',params)
}
